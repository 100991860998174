import { Slot } from "@radix-ui/react-slot";
import type { VariantProps } from "cva";
import { cva } from "cva";
import * as React from "react";

import { cn } from "@flow/ui";

const buttonVariants = cva({
  base: "inline-flex items-center justify-center rounded-md text-sm font-normal ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  variants: {
    variant: {
      default: "bg-primary text-background hover:bg-primary-foreground",
      destructive:
        "bg-destructive text-destructive-foreground hover:bg-destructive/90",
      outline: "border border-input hover:bg-primary/10 hover:text-primary",
      secondary: "bg-foreground text-background hover:bg-foreground/80",
      ghost: "hover:bg-primary/10 hover:text-primary",
      inverted: "bg-background hover:bg-background/80",
      link: "text-primary underline-offset-4 hover:underline",
    },
    size: {
      default: "h-10 px-4 py-2",
      sm: "h-9 rounded-md px-3",
      xsm: "h-6 rounded-md px-2",
      lg: "h-11 rounded-md px-8",
      icon: "h-10 w-10",
      "sm-icon": "h-6 w-6",
    },
  },
  defaultVariants: {
    variant: "default",
    size: "default",
  },
});

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };
